@import 'src/asset/css/colors.scss';

.launcher {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $ship-gray;
}
.launcher-container {
  margin: auto;
}
