.view-menu-container {
    z-index: 90;
}

.viewBtn-container {
    display: flex;
    position: fixed;
    width: 15vw;
    height: 15vw;
    flex-direction: column;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.3);
    // z-index: 100;
    @media only screen and (min-width: 1366px) {
        top: 10px;
        right: 0px;
    }
    @media only screen and (max-width: 1366px) and (orientation:portrait) {
        top: 10px;
        right: 0px;
        width: 50vw;
        height: 50vw;
    }
    @media only screen and (max-width: 1366px) and (orientation: landscape) {
        bottom: 100px;
        right: 0px;
        top: 10px;
        width: 25vw;
        height: 25vw;
    }
}

.hide-viewBtn-container {
    display: flex;
    right: 15vw;
    position: fixed;
    width: 30px;
    height: 30px;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.4);
    // z-index: 90;
    color: white;
    align-items: center;
    justify-content: center;
    border: hidden;
    cursor: pointer;
    border-radius: 3px 0px 0px 3px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }
    @media only screen and (min-width: 1366px) {
        top: 60px;
    }
    @media only screen and (max-width: 1366px) and (orientation:portrait) {
        top: 60px;
    }
    @media only screen and (max-width: 1366px) and (orientation: landscape) {
        top: 60px;
    }
}

.floorplan-viewButton {
    width: 100%;
    height: 80%;
    display: flex;
    padding: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 0);
    // z-index: 100;
    cursor: pointer;
    outline: 0;
    justify-content: center;
    align-items: center;
    color: white;
    flex-grow: 1;
    overflow-x: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    border-radius: 6px 6px 0px 0px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.dollhouse-viewButton {
    width: 100%;
    height: 20%;
    display: flex;
    padding: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 0);
    // z-index: 100;
    cursor: pointer;
    outline: 0;
    justify-content: center;
    align-items: center;
    color: white;
    flex-grow: 1;
    overflow-x: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    border-top: 1px solid white;
    border-radius: 0px 0px 6px 6px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.insight-viewButton {
    display: flex;
    top: 20px;
    left: 20px;
    position: fixed;
    width: 50px;
    height: 50px;
    flex-direction: column;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0);
    z-index: 100;
    color: white;
    align-items: center;
    justify-content: center;
    border: hidden;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.buttonText {
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft JhengHei;
}