
@import './../../../style/index.scss';

.qrcode-board{
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    z-index: 110;
    .qrcode-board-panel{
        background: rgba(88,88,90,0.9);
        border-radius: 10px;
        height: 400px;
        width: 700px;
        position: relative;
        z-index: 100;
        @include pad {
            height: 110vh;
            width: 100vw;
            border-radius: 0px;
        }
        .qrcode-close-btn{
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 15px;
            width: 25px;
            height: 25px;
            align-items: center;
            justify-content: center;
            display: flex;
            @include pad {
                height: 40px;
                width: 40px;
                top: 40px;
                right: 25px;
            }
            @include mobile {
                top: 30px;
                right: 15px;
                height: 20px;
                width: 20px;
            }
            .qrcode-close-btn-img{
                width: 100%;
                height: 100%;
            }
        }
    }
    
}


.qrcode-board-tilte{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size:20px;
        width: 200px;
        font-weight: bolder;
        color: white;
        display: inline-block;
        padding: 30px 0px;
        text-align: center;
        border-bottom: $main-color solid 4px;
        @include pad{
            font-size:26px;
            width: 60%;
        }
        @include mobile{
            font-size:20px;
        }
    }
}

.qrcode-board-contain{
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include pad{
        flex-direction: column;
    }
    .qrcode-container{
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img{
            width: 237px;
            height: 237px;
            @include pad{
                width: 300px;
                height: 300px;
            }
            @include mobile{
                width: 240px;
                height: 240px;
            }
        }
    }
    .share-container{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include pad{
            width: 100%;
            justify-content: flex-start;
            margin-top:20px;
        }
        .share-fb{
            cursor: pointer;
            margin-bottom: 20px;
            width: 60%;
            min-width: 240px;
            height: 40px;
            background-color: rgb(71, 89, 147);
            border-radius: 6px;
            text-align: center;
            padding: 5px 0px;
            a{
                color: white;
                font-size: 20px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    margin-right: 20px;
                }
            }
        }
        .share-twitter{
            cursor: pointer;
            width: 60%;
            min-width: 240px;
            height: 40px;
            background-color: rgb(80, 171, 241);
            border-radius: 6px;
            text-align: center;
            padding: 5px 0px;
            a{
                color: white;
                font-size: 20px;
                font-weight: 400;
                line-height: 40px;
                text-align: center;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    margin-right: 20px;
                }
            }
        }
    }
}