.popedFloorPlan {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.defaultFloorPlan {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
