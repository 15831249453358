@import 'src/asset/css/colors';

.progress {
  position: relative;
  height: 6px;
  width: 200px;
  background-color: $alto;
  border-radius: 25px;
  box-shadow: inset 0 -1px 1px rgba($white, 0.3);
  overflow: hidden;
}

.progress-inner {
  display: block;
  height: 100%;
  background-color: mix($eminence, $pink-istaging);
  background-image: linear-gradient(to right, $eminence, $pink-istaging);
  position: relative;
  transform: scaleX(0);
  transition: transform 0.2 linear;
  transform-origin: left center;
}

.progress-inner-yc{
  display: block;
  height: 100%;
  background-color: $yc-yellow;
  background-image: $yc-yellow;
  position: relative;
  transform: scaleX(0);
  transition: transform 0.2 linear;
  transform-origin: left center;
}
