// pink
$pink-istaging: #ec0974;

// gray
$alto: #d8d8d8;
$silver: #bababa;
$scarpa-flow: #58585a;
$tundora: #4a4a4a;
$ship-gray: #444446;

// purple
$eminence: #662e91;

$white: #fff;
$black: #000;

//yc-yellwow
$yc-yellow: #FFCC33