@import './../../../style/index.scss';

.viewerContainer {
    width: 100vw;
    height: 100vh;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 80px;
    z-index: 100;
}

.Scene-close {
    width: 0%;
    height: 100%;
    position: absolute;
    bottom: 0;
    // display: none;
}

.Scene-open {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}

.mainScene-open {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
    @media only screen and (orientation: portrait) {
        width: 100%;
        height: 50%;
        position: absolute;
        top: 0;
    }
}

.secondScene-open {
    width: 50%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    @media only screen and (orientation: portrait) {
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
    }
}

.select-style {
    display: flex;
    flex-direction: column;
    z-index: 101;
    right: 0%;
    position: absolute;
    width: 300px;
    max-width: 80%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    @media only screen and (orientation: portrait) {
        display: block;
        flex-direction: column;
        z-index: 100;
        bottom: 0%;
        position: absolute;
        width: 100%;
        max-width: none;
        height: 80%;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        z-index: 100;
        bottom: 0%;
        position: absolute;
        width: 100%;
        max-width: none;
        height: 80%;
        border-radius: 10px 10px 0 0;
        background-color: rgba(0, 0, 0, 0.7);
    }
}

.select-style-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    margin: 14px 65px 16px 92px;
}

.close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    color: black;
    font-size: xx-large;
}

.styleboardclose {
    .closeBtn {
        cursor: pointer;
        position: absolute;
        left: calc(50% + 20px);
        top: 20px;
        color: black;
        font-size: xx-large;
        @media only screen and (hover: none) and (pointer: coarse) and (orientation: portrait) {
            top: calc(50% + 20px);
            left: 20px;
            cursor: pointer;
            position: absolute;
            color: black;
            font-size: xx-large;
        }
    }
}

.select-style-button-list {
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
    margin-top: 25px;
    display: flex;
    padding: 0px;
    height: 100%;
    overflow: auto;
    @media only screen and (orientation: portrait) {
        justify-content: flex-start;
        margin: 0;
        gap: 0px;
        height: calc(100% - 51px);
    }
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        flex-direction: row;
        justify-content: center;
    }
}

.select-style-button {
    z-index: 100;
    color: white;
    font-size: 14px;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
    padding: 0;
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
        width: 166px;
        height: 80%;
    }
    img {
        width: 80%;
        border-radius: 25px;
        border-style: solid;
        border-color: transparent;
    }
    img:hover {
        border-color: #EEE;
    }
    .select-style-button-name {
        margin: 0;
        @media only screen and (orientation: portrait) {
            padding: 10px 0;
        }
    }
}

.show-style-button-old {
    position: absolute;
    width: 120px;
    height: 50px;
    z-index: 100;
    color: rgba(237,12,116, 1.0);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        bottom: 128px;
        right: 40px;
    }
    @media only screen and (max-width: 1024px) {
        right: 10px;
        top: 10px;
    }
}

.show-ruler-button {
    position: absolute;
    width: 40px;
    z-index: 100;
    background: transparent;
    border-style: none;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        bottom: 130px;
        right: 160px;
    }
    @media only screen and (max-width: 1024px) {
        right: 120px;
        top: 15px;
    }
}

.cancel-button {
    position: absolute;
    top: 100px;
    right: 40px;
    width: 40px;
    z-index: 100;
    background: transparent;
    border-style: none;
    cursor: pointer;
}

// select dropdown
.show-dropdown-close {
    position: absolute;
    width: 150px;
    height: 50px;
    z-index: 100;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        top: 20px;
        left: 20px;
    }
}

.show-dropdown-close-mobile {
    position: absolute;
    width: 200px;
    height: 40px;
    z-index: 100;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    top: 20px;
    left: 20px;
}

.show-dropdown-close-mobile-bt {
    position: absolute;
    width: 100px;
    height: 40px;
    z-index: 100;
    color: white;
    background: rgba(0, 0, 0, 0);
    border-radius: 23px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    top: 20px;
    left: 20px;
}

.dropdown-close-down {
    position: absolute;
    top: 35%;
    right: 20px;
    border: solid #d8d8d8;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.dropdown-close-mobile-frame {
    position: absolute;
    top: 20%;
    right: 50%;
    border: solid rgba(212, 212, 212, 0.6);
    //border: solid #d8d8d8;
    border-width: 0 2px 0 0;
    display: inline-block;
    padding: 12px;
}

.dropdown-close-value {
    position: absolute;
    top: 25%;
    left: 12%;
}

.show-dropdown-open {
    position: absolute;
    width: 150px;
    height: 50px;
    z-index: 100;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    top: 0px;
    left: 0px;
}

.show-dropdown-open:hover {
    background: rgba(46, 46, 46, 0.55);
}

.dropdown-open-value {
    position: absolute;
    width: 150px;
    height: 50px;
    z-index: 100;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-style: none;
    font-size: 16px;
    cursor: pointer;
    @media only screen and (min-width: 1025px) {
        left: 20px;
    }
}

.dropdown-open-value:hover {
    background: rgba(46, 46, 46, 0.55);
}

.dropdown-open-up {
    position: absolute;
    top: 40%;
    left: 48%;
    border: solid #d8d8d8;
    border-width: 3px 0 0 3px;
    display: inline-block;
    padding: 3px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.dropdown-open-down {
    position: absolute;
    top: 40%;
    left: 48%;
    border: solid #d8d8d8;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
}

.multifloor-dropdown {
    position: absolute;
    z-index: 100;
    left: 20px;
    border-radius: 60px;
    background-image: linear-gradient(to bottom, rgba(216, 216, 216, 0), #ffffff 48%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    justify-content: center;
    // @media only screen and (min-width: 1366px) {
    //     bottom: 130px;
    // }   
    @media only screen and (max-width: 1366px) {
        bottom: 0px;
    }
}

.multifloor-button-select {
    position: absolute;
    z-index: 100;
    opacity: 0.8;
    border-radius: 100px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    border: solid 2px #fdcc33;
    background-color: #000000;
    color: white;
    @media only screen and (min-width: 1366px) {
        font-size: 100%;
    }
    @media only screen and (max-width: 1366px) {
        font-size: 50%;
    }
}

.multifloor-button {
    position: absolute;
    z-index: 95;
    opacity: 0.39;
    border-radius: 100px;
    border: 0;
    background-color: #000000;
    color: white;
    @media only screen and (min-width: 1366px) {
        font-size: 100%;
    }
    @media only screen and (max-width: 1366px) {
        font-size: 50%;
    }
}

.multifloor-button-up {
    position: absolute;
    border: solid #d8d8d8;
    display: inline-block;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
    @media only screen and (min-width: 1366px) {
        border-width: 4px 0 0 4px;
        padding: 8px;
        left: 45%;
        top: 5%;
    }
    @media only screen and (max-width: 1366px) {
        border-width: 4px 0 0 4px;
        padding: 5px;
        left: 42%;
        top: 5%;
    }
}

.multifloor-button-down {
    position: absolute;
    border: solid #d8d8d8;
    display: inline-block;
    transform: rotate( 45deg);
    -webkit-transform: rotate( 45deg);
    @media only screen and (min-width: 1366px) {
        border-width: 0 4px 4px 0;
        padding: 8px;
        left: 45%;
        bottom: 5%;
    }
    @media only screen and (max-width: 1366px) {
        border-width: 0 4px 4px 0;
        padding: 5px;
        left: 42%;
        bottom: 5%;
    }
}

.waterprint {
    position: absolute;
    pointer-events: none;
    z-index: 90;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.qrcode-board {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    z-index: 110;
}

.qrcode-board-background {
    position: absolute;
    pointer-events: none;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: none;
}

.qrcode-close {
    margin: -70px 0 35px 250px;
    width: 36px;
    height: 36px;
    opacity: 0.5;
    background-color: #000000;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.qrcode-board-image {
    width: 450px;
    height: 350px;
    z-index: 115;
    padding: 15px 26px 16px;
    border-radius: 5px;
    background-color: rgba(103, 103, 103, 0.8);
    .qrcode-img {
        width: 200px;
        height: 200px;
    }
}

.tool-bar {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .show-style-button {
        width: 50px;
        height: 30px;
        z-index: 100;
        color: #de2574;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 23px;
        border-style: none;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 10px;
        position: relative;
        // mobile 模式，換裝靠左，文字垂直置中，背景透明 (使用大工具列背景)
        @media only screen and (hover: none) and (pointer: coarse), (max-width: 1366px) {
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.7);
            margin-bottom: 0px;
        }
    }
    .functional-tool-bar {
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;
        justify-content: center;
        align-items: center;
        button {
            margin-bottom: 10px;
            background: transparent;
            border-style: none;
            cursor: pointer;
            img {
                width: 22px;
                height: 22px;
            }
        }
        div{
            margin-bottom: 10px;
        }
        // mobile 模式，工具列靠左
        @media only screen and (hover: none) and (pointer: coarse),
        (max-width: 1366px) {
            display: flex;
            margin-top: 10px;
            // break point < 414px 隱藏按鈕
            @media only screen and (max-width: 414px) {
                .mobile-hide-button {
                    display: none;
                }
            }
            @media only screen and (max-height: 414px) {
                .mobile-hide-button {
                    display: none;
                }
            }
        }
    }
    // mobile 模式，合併換裝與工具列
    @media only screen and (hover: none) and (pointer: coarse),
    (max-width: 1366px) {
        display: flex;
        justify-content: space-between;
    }
}

.tool-bar-topview {
    position: fixed;
    bottom: 25px;
    right: 32px;
    .show-style-button {
        display: none;
    }
    .functional-tool-bar {
        button {
            background: transparent;
            border-style: none;
            cursor: pointer;
            img {
                width: 22px;
                height: 22px;
            }
        }
        // mobile 模式，工具列靠右
        @media only screen and (hover: none) and (pointer: coarse),
        (max-width: 1366px) {
            // break point < 414px 隱藏按鈕
            @media only screen and (max-width: 414px) {
                .mobile-hide-button {
                    display: none;
                }
            }
            @media only screen and (max-height: 414px) {
                .mobile-hide-button {
                    display: none;
                }
            }
        }
    }
}

.verify-section {
    position: fixed;
    right: 0;
    top: 0;
    padding: 20px;
    z-index: 100;
    button {
        border: none;
        cursor: pointer;
    }
    .trigger-btn {
        width: 100px;
        height: 50px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
        &:hover {
            background-color: rgba(0, 0, 0, 0.7);
        }
    }
    .check-box {
        position: fixed;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        width: 300px;
        height: 400px;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .complete-text,
    .error-text {
        border: none;
        width: 100px;
        height: 50px;
        cursor: default;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
    }
    .error-text {
        color: rgb(253, 74, 74)
    }
    .back-section {
        width: 100%;
        height: 10%;
        button {
            color: #fff;
            font-size: 24px;
            background-color: transparent;
        }
    }
    .action-section {
        width: 100%;
        height: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.pass {
            background-color: #000000;
            button {
                color: #FFCC33;
            }
        }
        &.fail {
            background-color: #fff;
            button {
                color: red;
            }
        }
        button {
            background-color: transparent;
            font-size: 48px;
            font-weight: bold;
        }
    }
    .input-section {
        width: 100%;
        .hint {
            color: rgb(253, 74, 74);
            font-size: 16px;
        }
        label {
            display: inline-block;
            font-size: 18px;
            color: #fff;
            margin: 4px 0;
        }
        textarea {
            font-size: 18px;
        }
    }
    .submit-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            background-color: #000;
            color: #fff;
            padding: 4px 16px;
            font-size: 18px;
            font-weight: bold;
        }
    }
    &.done {
        display: none;
    }
}

