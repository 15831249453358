@import 'asset/css/colors';

.ruler {
  position: absolute;
  transform: translate(50%, 50%);
  transition: transform 0.5s;
  background-color: transparent;
  color: white;
  white-space: nowrap;
}
