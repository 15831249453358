@import './../../style/index.scss';
.svg-ruler{
    height: 25px;
    width: 25px;
    padding: 5px;
    background: rgba(104, 98, 98,0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @include mobile{
        width: 20px;
        height: 20px;
        }
    svg{
        width: 25px;
        height: 25px;
        @include mobile{
            width: 18px;
            height: 18px;
        }
    }
}
.svg-ruler:hover {
    path{
        fill: $main-color
    }
}
