@import './../../../style/index.scss';
.swiper{
    width: 100%;
    height: 240px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    .swiper-toggle-btn{
        cursor: pointer;
        width: 150px;
        height: 40px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 20px 20px 0px 0px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .swiper-toggle-btn-icon{
            border: solid white;
            border-width: 3px 0px 0px 3px;
            width: 10px;
            height: 10px;
            transform: rotate(-135deg);
        }
    }
    .swiper-panel{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgb(0, 0, 0));
        .scroll-container{
            overflow-x: scroll;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .swiper-item{
                margin-right:20px;
                margin-bottom:20px;
                cursor: pointer;
                img{
                    width: 150px;
                    height: 100px;
                    border-radius: 3px;
                    border:red;
                }
                img:hover{
                    border: $main-hover-color solid 2px;
                }
                div{
                    text-align: center;
                    color:white;
                    font-size: 14px;
                }
            }
        }
    }
}
